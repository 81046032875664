























































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { ReferralViewModel } from '../viemmodel/referral-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: ReferralViewModel

  desserts = [
    {
      avatar: '',
      name: '@Dragon',
      date: '2022-01-23, 10:00 pm',
      reward: '+ 10 INK',
      icon: '',
    },
    {
      avatar: '',
      name: '@Dragon',
      date: '2022-01-23, 10:00 pm',
      reward: '+ 10 INK',
      icon: '',
    },
    {
      avatar: '',
      name: '@Dragon',
      date: '2022-01-23, 10:00 pm',
      reward: '+ 10 INK',
      icon: '',
    },
  ]

  get flex() {
    return (
      (this.$vuetify.breakpoint.width >= 737 && this.$vuetify.breakpoint.width < 1280) ||
      this.$vuetify.breakpoint.width > 1737
    )
  }
}
